// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$notarios-color-primary: (
  50: #fce9ec,
  100: #f8c8cd,
  200: #e39292,
  300: #d46868,
  400: #db4645,
  500: #dc332a,
  600: #ce292a,
  700: #bd1f24,
  800: #b0171e,
  900: #a10812,
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: rgba(0, 0, 0, 0.87),
    500: rgba(255, 255, 255, 0.87),
    600: rgba(255, 255, 255, 0.87),
    700: rgba(255, 255, 255, 0.87),
    800: rgba(255, 255, 255, 0.87),
    900: rgba(255, 255, 255, 1)
  )
);

$notarios-color-accent: (
  50: #f5efe3,
  100: #e8d7b8,
  200: #dabd8c,
  300: #cfa45c,
  400: #ca9237,
  500: #c3811b,
  600: #c07818,
  700: #ba6b12,
  800: #b35d0b,
  900: #a94801,
  contrast: (
    50: rgba(255, 255, 255, 0.87),
    100: rgba(255, 255, 255, 0.87),
    200: rgba(255, 255, 255, 0.87),
    300: rgba(255, 255, 255, 0.87),
    400: rgba(255, 255, 255, 0.87),
    500: rgba(255, 255, 255, 0.87),
    600: rgba(255, 255, 255, 0.87),
    700: rgba(255, 255, 255, 0.87),
    800: rgba(255, 255, 255, 0.87),
    900: rgba(255, 255, 255, 0.87)
  )
);


$notarios-typography: mat.define-typography-config(
  $font-family: 'Poppins',
  $headline-1: mat.define-typography-level(60px, 60px, 400, $letter-spacing: s),
  $headline-2: mat.define-typography-level(48px, 48px, 400, $letter-spacing: normal),
  $headline-3: mat.define-typography-level(36px, 36px, 400, $letter-spacing: normal),
  $headline-4: mat.define-typography-level(24px, 24px, 500, $letter-spacing: normal),
  $headline-5: mat.define-typography-level(18px, 18px, 500, $letter-spacing: normal),
  $headline-6: mat.define-typography-level(15px, 15px, 500, $letter-spacing: normal),
  $caption: mat.define-typography-level(12px, 12px, 500, $letter-spacing: normal),
  $button: mat.define-typography-level(15px, 15px, 500, $letter-spacing: normal)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$notarios-primary: mat.define-palette($notarios-color-primary, 900);
$notarios-accent: mat.define-palette($notarios-color-accent, 200);

// The warn palette is optional (defaults to red).
$notarios-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$notarios-theme: mat.define-light-theme((
  color: (
    primary: $notarios-primary,
    accent: $notarios-accent,
    warn: $notarios-warn,
  ),
  typography: $notarios-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($notarios-theme);


html, body {
  height: 100%;
}

body {
  margin: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  background-color: #EAF1F7 !important;
}

.drawer-container {
  color: mat.get-color-from-palette($notarios-accent);
}

.drawer-title {
  background-color: mat.get-color-from-palette($notarios-primary, 900);
}

.bg-primary-notarios {
  background-color: mat.get-color-from-palette($notarios-primary, 900);
  color: mat.get-color-from-palette($notarios-primary, '900-contrast');
}

.side-nav-bg-primary {
  background-color: mat.get-color-from-palette($notarios-primary, 900);
  color: mat.get-color-from-palette($notarios-primary, '900-contrast');
  width: 265px;
  border: none;
}

.mat-cell-striped {
  background: #F5F5F5 !important;
}

.mat-cell {
  padding: 15px 10px !important;
}